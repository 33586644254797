var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Indicadores com Erros ")]),_c('v-card-text',[_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"id":"btn-exportar-xlsx","color":"primary","dark":"","elevation":"1","relative":"","text":"","medium":"","loading":_vm.downloadingArquivo},on:{"click":function($event){return _vm.downloadErrosIndicadores()}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Exportar XLSX ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.valores,"search":_vm.search,"headerProps":{ sortByText: 'Ordenar Por' },"footerProps":{ itemsPerPageOptions: [10, 50, 100, -1] },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([_vm._l((_vm.indicadoresFields),function(indicador){return {key:_vm.getItemSlotName(indicador),fn:function(ref){
var item = ref.item;
return [(_vm.decimalFields.includes(indicador))?_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item[indicador] === null ? "-" : item[indicador]))+" ")]):_c('span',[_vm._v(" "+_vm._s(item[indicador] === null || item[indicador] === "/" ? "-" : item[indicador])+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"padding-bottom":"1.5px"},attrs:{"color":item[("val_" + indicador)] === 'OK'
                          ? 'success'
                          : 'error'}},[_vm._v(" "+_vm._s(item[("val_" + indicador)] === "OK" ? "mdi-check-circle-outline" : "mdi-close-circle")+" ")])],1)]}}],null,true)},[(item[("val_" + indicador)] !== 'OK')?_c('span',{staticClass:"error--text font-weight-bold"},[_vm._v(" ERRO: ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item[("val_" + indicador)] === "OK" ? "OK ou não importado" : item[("val_" + indicador)])+" ")])])]}}})],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }