<template>
  <span>
    <v-card>
      <v-card-title primary-title>
        Indicadores com Erros
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto" justify="space-between">
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              hide-details
              single-line
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              id="btn-exportar-xlsx"
              color="primary"
              dark
              elevation="1"
              relative
              text
              medium
              :loading="downloadingArquivo"
              @click="downloadErrosIndicadores()"
            >
              <v-icon left size="30px">
                mdi-microsoft-excel
              </v-icon>
              Exportar XLSX
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="valores"
              :search.sync="search"
              :headerProps="{ sortByText: 'Ordenar Por' }"
              :footerProps="{ itemsPerPageOptions: [10, 50, 100, -1] }"
              no-data-text="Nenhum registro disponível"
              no-results-text="Nenhum registro correspondente encontrado"
              :mobile-breakpoint="10"
            >
              <template
                v-for="indicador in indicadoresFields"
                v-slot:[getItemSlotName(indicador)]="{ item }"
              >
                <span v-if="decimalFields.includes(indicador)">
                  {{
                    item[indicador] === null
                      ? "-"
                      : item[indicador] | parseNumberToFloatBrIgnoringNaN
                  }}
                </span>
                <span v-else>
                  {{
                    item[indicador] === null || item[indicador] === "/"
                      ? "-"
                      : item[indicador]
                  }}
                </span>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        style="padding-bottom: 1.5px;"
                        :color="
                          item[`val_${indicador}`] === 'OK'
                            ? 'success'
                            : 'error'
                        "
                      >
                        {{
                          item[`val_${indicador}`] === "OK"
                            ? "mdi-check-circle-outline"
                            : "mdi-close-circle"
                        }}
                      </v-icon>
                    </span>
                  </template>
                  <span
                    v-if="item[`val_${indicador}`] !== 'OK'"
                    class="error--text font-weight-bold"
                  >
                    ERRO:
                  </span>
                  <span>
                    {{
                      item[`val_${indicador}`] === "OK"
                        ? "OK ou não importado"
                        : item[`val_${indicador}`]
                    }}
                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  import ManutencaoTecnicosService from "../../services/ManutencaoTecnicosService";

  export default {
    name: "CardErrosIndicadores",
    props: {
      base: {
        type: String,
        required: true,
      },
      competencia_de: {
        type: String,
        required: true
      },
      competencia_ate: {
        type: String,
        required: true
      },
      temas_validos: {
        type: Array,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      valores: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      search: "",
      decimalFields: ["qtd_consumidor", "qtd_reclamacao", "carregamento"],
      indicadoresFields: [
        "qtd_consumidor",
        "qtd_reclamacao",
        "inspecao_visual",
        "inspecao_termografica",
        "manutencao_preventiva",
        "manutencao_corretiva",
        "carregamento",
      ],
      downloadingArquivo: false,
    }),
    computed: {
      headers() {
        let headers = [];

        for (const key in this.valores[0]) {
          if (Object.hasOwnProperty.call(this.valores[0], key)) {
            if (!key.startsWith("val")) {
              headers.push({
                text: key.toUpperCase().replace(/_/g, " "),
                value: key,
              });
            }
          }
        }

        return headers;
      },
    },
    methods: {
      getItemSlotName(indicador) {
        return `item.${indicador}`;
      },
      downloadErrosIndicadores() {
        this.downloadingArquivo = true;

        this.$toast.info(
          "Preparando seu arquivo para download, por favor, aguarde",
          "",
          {
            position: "topRight",
            timeout: 5000,
          }
        );

        ManutencaoTecnicosService.downloadErrosIndicadores(
          this.competencia_de,
          this.competencia_ate,
          this.base,
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
              "download",
              `erros_indicadores-${this.base}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => (this.downloadingArquivo = false));
      },
    },
  };
</script>

<style></style>
